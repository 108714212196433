const $ = require('jquery');
global.$ = global.jQuery = $;
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');
require('gsap/all.js');

// in gsap/all.js we import everything besides Tween and Time
import TweenLite, {Ease, Power0, Power1, Power2, Power3, Power4, Linear, _gsScope} from 'gsap';
import {TweenMax, TimelineLite, TimelineMax} from 'gsap';

//var ScrollMagic = require("scrollmagic");

// npm imports-loader helps to avoide: These dependencies were not found: TimelineMax, TweenMax
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators'
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'

// START footer-logo
	var $slogans = $("h5.slogan").find("strong");
	var $holder = $("#holder");
	$slogans.parent().css({position : "absolute", top:"0px", left:"0px"});
	var transitionTime = 0.8;
	var slogansDelayTime = 4.4;
	var totalSlogans = $slogans.length;
	var oldSlogan = 0;
	var currentSlogan = -1;

	switchSlogan();

	function switchSlogan() {
	oldSlogan = currentSlogan;
	if(currentSlogan < totalSlogans-1) {
		currentSlogan ++
	} else {
		currentSlogan = 0;
	}
	TweenLite.to($slogans.eq(oldSlogan), transitionTime, {top:-20, alpha:0, rotationX: 90});
	TweenLite.fromTo($slogans.eq(currentSlogan), transitionTime, {top:20, alpha:0, rotationX: -90 }, {top:0, alpha:1, rotationX:0});
	TweenLite.delayedCall(slogansDelayTime, switchSlogan);
	};
// END

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});

$(document).ready(function (){
	
	//var controller = new ScrollMagic.Controller();
	
// START animate the start logo
  TweenLite.set(".logo-path", {visibility:"visible"});
  var tl = new TimelineLite();
  tl.fromTo(".logo-path", 10, {drawSVG:0}, {drawSVG:"100%"});
// END
	
	$('#tocontact').click(function(e){
    e.preventDefault();
		$('#allabout').modal('hide')
    var href = $(this).attr('href');
    window.location = href;
  });

});

